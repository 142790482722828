<template>
    <div class="order-main-detail">
        <div class="container">
            <order-detail-meta :id="id" mode="my"></order-detail-meta>
            <md-button @click="goback" class="md-icon-button close back">
                <md-icon>keyboard_backspace</md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
import OrderDetailMeta from "@/components/Order/OrderDetailMeta";

export default {
    props: ["id"],
    components: {
        OrderDetailMeta
    },
    methods: {
        goback() {
            this.$router.go(-1);
        }
    }
};
</script>
<style lang="less">
.order-main-detail {
    .container {
        position: relative;
    }
    .back {
        position: absolute;
        right: 10px;
        top: 4px;
        z-index: 9;
    }
}
</style>